// Dependencies
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

// Styles
import { VideosWrapper } from './Videos.styled'

// Components
import Modal from '../Modal/Modal'
import { playSVG } from '../../assets/icons'

// Helpers
import { getRandom, getRandoms, getYoutubeId } from '../../helpers/utils'

const Gallery = ({
  gallery,
  id,
  inline = false,
  size = -1,
  compact = false,
  random = false,
}) => {
  const LIMIT = 4
  const [limit, setLimit] = useState(LIMIT)
  let [isOpenModal, setIsOpenModal] = useState(false)
  let [activeId, setActiveId] = useState(0)
  let [videosToShow, setVideosToShow] = useState([])

  const handleCloseModal = () => {
    setIsOpenModal(false)
  }

  const openModal = (index) => {
    setActiveId(index)
    setIsOpenModal(true)
  }

  useEffect(() => {
    // const galleryVideosToShow = [...(compact && size > 1 ? getRandoms(gallery.galleryVideos, Math.min(size, gallery.galleryVideos.length), true) : [getRandom(gallery.galleryVideos)])]
    const galleryVideosToShow = random
      ? getRandoms(gallery.galleryVideos, gallery.galleryVideos.length, true)
      : gallery.galleryVideos

    if (compact && inline) {
      setVideosToShow([getRandom(galleryVideosToShow)])
    } else {
      setVideosToShow(galleryVideosToShow)
    }
  }, [gallery])

  useEffect(() => {
    document.body.style.overflow = isOpenModal ? 'hidden' : 'unset'
  }, [isOpenModal])

  const fullWidth = compact && size === 1
  const hasMultiple = compact && size > 1 && gallery.galleryVideos.length > 1

  return (
    <>
      <VideosWrapper
        className={`${fullWidth ? 'full-width' : ''} ${
          hasMultiple ? 'multiple' : ''
        }`}
      >
        <h3>{gallery.galleryName}</h3>
        <div>
          {videosToShow.slice(0, hasMultiple ? size : limit).map((video, i) => {
            const youtubeId = getYoutubeId(video.url)
            return inline ? (
              <div key={`${id}_video_${i}_${youtubeId}`} className="video">
                <figure>
                  <div>
                    <iframe
                      src={`//www.youtube.com/embed/${youtubeId}?autoplay=0&rel=0`}
                      frameBorder="0"
                      allowFullScreen
                    />
                  </div>
                  <figcaption className="video-caption">
                    {video.title}
                  </figcaption>
                </figure>
              </div>
            ) : (
              <a
                onClick={() => openModal(i)}
                key={`${id}_video_${i}_${youtubeId}`}
                className="video link"
              >
                <figure>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `${playSVG} <img src="//img.youtube.com/vi/${youtubeId}/0.jpg" />`,
                    }}
                  />
                  <figcaption className="video-caption">
                    {video.title}
                  </figcaption>
                </figure>
              </a>
            )
          })}
        </div>
        {!compact && limit < videosToShow.length && (
          <button className="button" onClick={() => setLimit(limit + LIMIT)}>
            <span>Mostrar Mais</span>
          </button>
        )}
      </VideosWrapper>
      {isOpenModal && (
        <Modal
          items={videosToShow.map((video) => {
            const youtubeId = getYoutubeId(video.url)
            return {
              title: video.title,
              html: `
                <div>
                  <iframe src="//www.youtube.com/embed/${youtubeId}?autoplay=1&rel=0" frameborder="0" allowfullscreen></iframe>
                </div>
              `,
            }
          })}
          activeIndex={activeId}
          type="videos"
          onClose={handleCloseModal}
        />
      )}
    </>
  )
}

// Components PropTypes
Gallery.propTypes = {
  gallery: PropTypes.shape({
    galleryName: PropTypes.string,
    galleryVideos: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      })
    ),
  }),
  id: PropTypes.string.isRequired,
  inline: PropTypes.bool,
}

export default Gallery
