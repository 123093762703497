// Dependencies
import styled from 'styled-components'

// Helpers
import { hexToRgb } from '../../helpers/utils'

// Constants
import { transition, maxWidth } from '../../constants/general'
import { colors } from '../../constants/colors'
import { type20 } from '../../constants/fonts'

export const ModalOverlay = styled.div`
  position: fixed !important;
  top: 0px !important;
  z-index: 0;
  width: 100%;
  height: 100%;
  display: block;
  background: rgba(${hexToRgb(colors.dark)}, 0.9);
  float: left;
`
export const ModalWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999;

  .slick-slider,
  .slick-track,
  .slick-slide {
    width: 100vw;
    height: 100vh;
  }

  .preview {
    position: fixed !important;
    top: 0px !important;
    z-index: 9999;
    width: 100%;
    height: 100%;
    display: block;
    background: rgba(${hexToRgb(colors.dark)}, 0.9);
    float: left;

    .grid {
      width: 100%;
      margin: 0 auto;
      position: relative;
      height: 100%;
    }

    .grid.images {
      .slideshow .slide div {
        ${maxWidth}
        display: block;
        height: 100%;
        width: 100%;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
      }
    }

    .slideshow {
      position: relative;
      display: block;
      height: 100%;
      width: 100%;
      clear: both;
      opacity: 1;

      .slide {
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        opacity: 0;
        vertical-align: top;

        ${transition}

        &.active {
          opacity: 1;
        }

        img {
          object-fit: contain !important;
        }
        img,
        iframe {
          width: 100%;
          height: 100%;
          position: relative;
          height: auto;
          top: 0;
          display: block;
          margin: 0 auto;
        }
        iframe {
          z-index: 3;
          position: absolute;
          width: 80%;
          height: 80%;
          top: 10%;
          left: 10%;
        }
        &.wider {
          img {
            height: auto;
            width: 100%;
          }
        }
      }
    }

    button {
      background: ${colors.black};
      border: none;
      padding: 0;
      width: 40px;
      height: 40px;
      display: block;
      position: absolute;
      top: 50%;
      z-index: 99999;
      cursor: pointer;
      ${transition}

      &.prev {
        left: 30px;
        margin-top: -30px;
      }

      &.next {
        right: 30px;
        margin-top: -30px;
      }
      &.close {
        right: 30px;
        top: 30px;
      }
      svg {
        margin: 0 auto;
        display: block;
        position: relative;
        overflow: hidden;
        width: 40px;
        height: 40px;
        circle {
          fill: rgba(${hexToRgb(colors.cream)}, 0);
          ${transition}
        }
        polygon {
          fill: rgba(${hexToRgb(colors.cream)}, 1);
          ${transition}
        }
      }
      &:hover {
        background: ${colors.white};
        svg {
          polygon {
            fill: rgba(${hexToRgb(colors.black)}, 1);
          }
        }
        opacity: 1;
      }
    }

    header,
    footer {
      position: absolute;
      display: block;
      width: 100%;
      z-index: 1;

      z-index: 1;
      ${type20}
      line-height: 1.3;
      font-weight: bold;
      ${transition}

      label {
        color: ${colors.cream};
        background: ${colors.black};
        position: relative;
        display: table;
        width: auto;
        padding: 10px;
        margin: 0;
      }
    }
    header {
      top: 30px;
      label {
        margin-left: 30px;
      }
    }
    footer {
      bottom: 30px;
      label {
        float: right;
        margin-right: 30px;
      }
    }
  }
`
