// Dependencies
import styled from 'styled-components'

// Helpers
import { hexToRgb } from '../../helpers/utils'

// Constants
import { colors } from '../../constants/colors'
import {
  playfairDisplay,
  type30,
  type20,
  type14,
  type25,
} from '../../constants/fonts'
import { sizesMax, maxWidthValue, sizesMin } from '../../constants/breakpoints'
import { transition } from '../../constants/general'

export const VideosWrapper = styled.div`
  > div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: calc(100% / 11 * 11);
  }

  padding-top: 10px;

  &:not(:last-child) {
    padding-bottom: 30px;
  }

  &.multiple,
  &.full-width {
    padding-bottom: 0 !important;
  }

  &.full-width .video {
    width: 100% !important;
  }

  &.multiple {
    .video-caption {
      display: none !important;
    }

    .video {
      width: calc(calc(100% / 11 * 5));
    }

    img,
    iframe {
      width: 100%;

      @media screen and (min-width: 0px) and (max-width: ${sizesMax.tablet}) {
        min-height: 10vw;
      }

      @media screen and (min-width: ${sizesMin.tablet}) {
        min-height: 10vw;
      }

      @media screen and (min-width: ${maxWidthValue}) {
        min-height: 50px;
      }
    }
  }

  .video {
    margin-bottom: 30px;
    position: relative;
    cursor: pointer;

    @media screen and (min-width: 0px) and (max-width: ${sizesMax.laptop}) {
      width: 100%;
    }
    @media screen and (min-width: ${sizesMin.laptop}) {
      width: calc(calc(100% / 11 * 5));
    }

    &-caption {
      padding: 20px;
      margin-top: 0px;
      color: ${colors.cream};
      background: ${colors.black};

      display: inline-block;
      ${type20}
      line-height: 1.3;
      font-weight: bold;
      ${transition}
    }
    figure div {
      position: relative;
      background: ${colors.black};
      ${transition}
    }
    &.link figure div::after {
      content: '';
      position: absolute;
      width: calc(100% - 10px);
      height: calc(100% - 10px);
      left: 0;
      top: 0;
      background: rgba(${hexToRgb(colors.black)}, 0);
      ${transition}
      border: solid 5px rgba(${hexToRgb(colors.white)}, 0);
    }

    &.link:hover figure div::after {
      background: rgba(${hexToRgb(colors.black)}, 0) !important;
      border: solid 5px rgba(${hexToRgb(colors.white)}, 1);
    }
    &.link:hover figcaption {
      color: ${colors.black};
      background: ${colors.white};
    }

    svg {
      background: ${colors.white};
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -20px 0 0 -20px;
      width: 40px;
      height: 40px;
      opacity: 0;
      padding: 0px;
      ${transition}

      circle {
        fill: rgba(${hexToRgb(colors.cream)}, 0);
        ${transition}
      }
      path {
        fill: rgba(${hexToRgb(colors.cream)}, 1);
        ${transition}
      }
    }
    &:hover {
      svg {
        background: ${colors.white};
        circle {
          fill: rgba(${hexToRgb(colors.cream)}, 0);
        }
        path {
          fill: rgba(${hexToRgb(colors.black)}, 1);
        }
        opacity: 1;
      }
    }
  }

  &:hover .video.link figure div::after {
    background: rgba(${hexToRgb(colors.black)}, 0.5);
  }

  img,
  iframe {
    width: 100%;
    @media screen and (min-width: 0px) and (max-width: ${sizesMax.laptop}) {
      min-height: 50vw;
    }

    @media screen and (min-width: ${sizesMin.laptop}) {
      min-height: 25vw;
    }

    @media screen and (min-width: ${maxWidthValue}) {
      min-height: 300px;
    }
  }
`
