// Dependencies
import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

// Components
import Gallery from './Gallery'

// Helpers
import { Context as MicroCopyContext } from '../../context/microcopyContext'
import { getRandom } from '../../helpers/utils'
import { getMicroCopy } from '../../helpers/microcopy'

const Videos = ({
  galleries,
  widget = false,
  size = 1,
  random = false,
  inline = false,
}) => {
  const { state } = useContext(MicroCopyContext)

  return (
    <div className="content">
      {(widget ? [getRandom(galleries)] : galleries).map((gallery, i) => {
        return (
          <Gallery
            gallery={
              widget
                ? {
                    ...gallery,
                  }
                : gallery
            }
            id={`gallery_${i}`}
            key={`gallery_${i}`}
            compact={widget}
            size={widget ? size : -1}
            random={widget && random}
            inline={inline}
          />
        )
      })}
      {widget && (
        <AniLink
          paintDrip
          hex="#ffe5cb"
          duration={1}
          className="button"
          to={'/videos'}
        >
          <span>
            {getMicroCopy({ key: `ctas.seeVideos`, data: state?.value })}
          </span>
        </AniLink>
      )}
    </div>
  )
}

// Components PropTypes
Videos.propTypes = {
  sectionTitle: PropTypes.string,
  sectionCopy: PropTypes.shape({
    childMarkdownRemark: PropTypes.shape({
      html: PropTypes.string,
    }),
  }),
  galleries: PropTypes.arrayOf(
    PropTypes.shape({
      galleryName: PropTypes.string,
      galleryVideos: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string.isRequired,
          url: PropTypes.string.isRequired,
        })
      ),
    })
  ),
}
Videos.defaultProps = {
  sectionTitle: null,
  sectionCopy: null,
}

export default Videos
