// Dependencies
import React, { useState, useEffect, useRef, useContext } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import Img from 'gatsby-image'

// Styles
import '../../assets/slick/slick.css'
import '../../assets/slick/slick-theme.css'
import { ModalWrapper } from './Modal.styled'

// Helpers, Context
import { Context as PlayerContext } from '../../context/playerContext'

// Components
import { closeSVG } from '../../assets/icons'
import NextArrow from './NextArrow'
import PrevArrow from './PrevArrow'
import { useComponentVisible } from '../../helpers/utils'

class ModalPortal extends React.Component {
  constructor(props) {
    super(props)
    this.el = document.createElement('div')
    this.modalRoot = document.getElementById('modal-root')
  }

  componentDidMount() {
    // The portal element is inserted in the DOM tree after
    // the Modal's children are mounted, meaning that children
    // will be mounted on a detached DOM node. If a child
    // component requires to be attached to the DOM tree
    // immediately when mounted, for example to measure a
    // DOM node, or uses 'autoFocus' in a descendant, add
    // state to Modal and only render the children when Modal
    // is inserted in the DOM tree.
    this.modalRoot.appendChild(this.el)
  }

  componentWillUnmount() {
    this.modalRoot.removeChild(this.el)
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.el)
  }
}

export const Modal = ({ items, type, title, onClose, activeIndex }) => {
  const {
    ref,
    isComponentVisible,
    setIsComponentVisible,
  } = useComponentVisible(false)
  // Getting state and methods from Context
  const {
    state: { playingState, currentAlbumId, currentTrack, currentTrackId },
    pauseTrack,
  } = useContext(PlayerContext)
  const [activeId, setActiveId] = useState(activeIndex || 0)
  const slider = useRef()

  const settings = {
    initialSlide: activeIndex,
    dots: false,
    lazyLoad: true,
    infinite: false,
    speed: 250,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    pauseOnFocus: true,
    pauseOnHover: true,
    pauseOnDotsHover: true,
    touchThreshold: 50,
    afterChange: (current) => setActiveId(current),
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  }

  const onKeyUp = (e) => {
    if (e.keyCode == 27) {
      onClose()
    }
    if (e.keyCode == 37) {
      slider.current.slickPrev()
    }
    if (e.keyCode == 39) {
      slider.current.slickNext()
    }
  }

  useEffect(() => {
    if (slider) document.addEventListener('keyup', onKeyUp, false)
    let wasPlaying = false
    if (playingState === 'playing' && type === 'videos') {
      wasPlaying = true
      pauseTrack({
        currentAlbumId: currentAlbumId,
        currentTrack: currentTrack,
        currentTrackId: currentTrackId,
      })
    }

    return () => {
      document.removeEventListener('keyup', onKeyUp, false)

      if (wasPlaying) {
        pauseTrack({
          currentAlbumId: currentAlbumId,
          currentTrack: currentTrack,
          currentTrackId: currentTrackId,
        })
      }
    }
  }, [slider])

  const [hasSetPosition, setHasSetPosition] = useState(false)

  useEffect(() => {
    if (slider.current && !hasSetPosition) {
      slider.current?.slickGoTo(activeIndex)
      setHasSetPosition(true)
    }
  }, [activeIndex, hasSetPosition, slider])

  return (
    type &&
    items && (
      <ModalPortal>
        <ModalWrapper>
          <div className="preview">
            <div
              className={`grid${type === 'videos' ? ' videos' : ''}${
                type === 'images' ? ' images' : ''
              }`}
            >
              <button
                className="close"
                onClick={() => onClose()}
                dangerouslySetInnerHTML={{ __html: closeSVG }}
              />
              {items &&
                items.length > 0 &&
                type === 'videos' &&
                items[activeId].title && (
                  <header className="title">
                    <label>{items[activeId].title}</label>
                  </header>
                )}
              {items && items.length > 0 && type === 'images' && title && (
                <header className="title">
                  <label>{title}</label>
                </header>
              )}
              {items && items.length > 0 && type === 'videos' && (
                <div className="slideshow">
                  <Slider ref={slider} {...settings}>
                    {items.map((item, i) => {
                      const key = 'slideshow_videos_' + i

                      return (
                        <div
                          key={key}
                          className={'slide' + (i == activeId ? ' active' : '')}
                        >
                          <div
                            dangerouslySetInnerHTML={
                              i == activeId
                                ? { __html: item.html }
                                : { __html: '' }
                            }
                          />
                        </div>
                      )
                    })}
                  </Slider>
                </div>
              )}
              {items && items.length > 0 && type === 'images' && (
                <div className="slideshow">
                  <Slider ref={slider} {...settings}>
                    {items.map((item, i) => {
                      const key = 'slideshow_images_' + i

                      return (
                        <div
                          key={key}
                          className={'slide' + (i == activeId ? ' active' : '')}
                        >
                          <Img fluid={item.fluid} objectFit={'contain'} />
                        </div>
                      )
                    })}
                  </Slider>
                </div>
              )}
              {items && items.length > 1 && (
                <footer className="counter">
                  <label>
                    {activeId + 1}/{items.length}
                  </label>
                </footer>
              )}
            </div>
          </div>
        </ModalWrapper>
      </ModalPortal>
    )
  )
}

// Components PropTypes
Modal.propTypes = {
  title: PropTypes.string,
  items: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        fluid: PropTypes.shape({
          src: PropTypes.string,
          srcSet: PropTypes.string,
          srcWebp: PropTypes.string,
          srcSetWebp: PropTypes.string,
          sizes: PropTypes.string,
          aspectRatio: PropTypes.number,
          base64: PropTypes.string,
        }).isRequired,
      })
    ),
    PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        html: PropTypes.string.isRequired,
      })
    ),
  ]),
  type: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  activeIndex: PropTypes.number,
}
Modal.defaultProps = {
  activeIndex: null,
  title: '',
}

export default Modal
